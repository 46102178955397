<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            v-if="solicitacaoCriarParaTreinamentos"
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/treinamento"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <SolicitacoesTreinamentoFormulario
          :loadingBtn="loadingBtn"
          :listRepresentantes="listRepresentantes"
          :listProdutos="listProdutos"
          :listAssuntos="listAssuntos"
          :representanteId="representanteId"
          @send="criar"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Solicitação criada com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import SolicitacoesTreinamentoFormulario from "@/components/solicitacoes/SolicitacoesTreinamentoFormulario";
import { criarTreinamentoSolicitacao } from "@/services/solicitacoes";
import { listarProdutos } from "@/services/produtos";
import { listarRepresentantes } from "@/services/user";
export default {
  name: "SolicitacoesTreinamentoCriar",
  components: { SolicitacoesTreinamentoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Lista de Treinamentos",
        disabled: false,
        to: "/treinamentos",
      },
      {
        text: "Criar Solicitação de Treinameto",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    listProdutos: [],
    listAssuntos: [
      { name: "Anatomia/Fisiologia", value: "Anatomia/Fisiologia" },
      { name: "Patologia", value: "Patologia" },
      { name: "Produto", value: "Produto" },
      { name: "Concorrência", value: "Concorrência" },
      { name: "BVC", value: "BVC" },
      { name: "P&A", value: "P&A" },
      { name: "Material promocional", value: "Material promocional" },
      { name: "Outros", value: "Outros" },
    ],
    listRepresentantes: [],
    representanteId: null
  }),
  created() {
    this.getProdutos();
    this.getRepresentantes();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarTreinamentoSolicitacao(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getProdutos() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getRepresentantes() {
      const resp = await listarRepresentantes();
      this.listRepresentantes = resp.data;
      if(this.$store.state.user.data.tipo === "Representante") {
        const representante = this.listRepresentantes.find(obj => obj.id === this.$store.state.user.data.id);
        this.representanteId = representante.id 
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "SolicitacoesTreinamento" });
    },
  },
  computed: {
    solicitacaoCriarParaTreinamentos() {
      return this.$store.state.user.data.permissoes.includes(
        "solicitacao.criarParaTreinamentos"
      );
    },
  },
};
</script>

<style></style>
