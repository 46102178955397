import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const getUserData = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.GET_USER);
    return data
}

export const getUserMeusDados = async () => {
    const { data } = await apiInstance.get(ENDPOINTS.MEUS_DADOS);
    return data
}

export const getFuncionarios = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.GET_USER, payload);
    return data
}

export const listFuncionarios = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LIST_USER, payload);
    return data
}

export const listColaborador = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LIST_COLABORADOR, payload);
    return data
}

export const listPermissoes = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LIST_PERMISSION, payload);
    return data
}

export const listMesas = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LIST_MESAS, payload);
    return data
}

export const exibirFuncionario = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_FUNCIONARIO + "/" + id);
    return data
}

export const editarAcessoDoFuncionario = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_ACESSOS_DO_FUNCIONARIO + "/" + id, payload);
    return data
}

export const importarFuncionario = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.IMPORTAR_FUNCIONARIO, payload);
    return data
}

export const listarMedicoAprovadores = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_MEDICO_APROVADORES, payload);
    return data
}

export const listarRepresentantes = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_REPRESENTANTES, payload);
    return data
}