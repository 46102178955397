<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="4">
          <v-autocomplete
            v-model="formData.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Produto"
            placeholder="Selecione o produto"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
            deletable-chips
          ></v-autocomplete>

          <v-autocomplete
            v-model="formData.assunto"
            :items="listAssuntos"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="value"
            label="Tema"
            placeholder="selecione o tema"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
            deletable-chips
          ></v-autocomplete>

          <v-textarea
            outlined
            label="Conteúdo"
            v-model="formData.conteudo"
            placeholder="Escreva sobre a dúvida"
            :clearable="true"
            rows="3"
            required
            :rules="rules.genericRules"
          ></v-textarea>

          <v-autocomplete
            v-model="formData.representanteId"
            :items="listRepresentantes"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Nome do Representante"
            placeholder="selecione o representante"
            outlined
            dense
            :clearable="true"
            required
            :rules="rules.selectRules"
            deletable-chips
            :disabled="$store.state.user.data.tipo === 'Representante'"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4">
      <v-btn
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
export default {
  name: "SolicitacoesTreinamentoFormulario",
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    listRepresentantes: {
      type: Array,
      required: true,
    },
    listProdutos: {
      type: Array,
      required: true,
    },
    listAssuntos: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    representanteId: {
      type: Number,
    }
  },
  data: () => ({
    formData: {
        representanteId: null,
        produtosId: null,
        assunto: null,
        conteudo: null
    },
    rules: rules,
    valid: true,
    isEdit: false,
  }),
  created() {
    if (this.$route.name === "SolicitacoesTreinamentoEditar") {
      this.formData.representanteId = this.item.representanteId;
      this.formData.produtosId = this.item.produtosId;
      this.formData.assunto = this.item.assunto;
      this.formData.conteudo = this.item.conteudo;
      this.isEdit = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async send() {
      if (this.formValid) {
        this.$emit("send", this.formData);
      }
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    representanteId: function(newValue) {
      if(this.$store.state.user.data.tipo === "Representante") {
        this.formData.representanteId = newValue
      }
    }
  }
};
</script>

<style></style>
